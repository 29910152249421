.newsletter {

    &--overlay {
        display: none;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &--show {
        display: block;
    }

    &__bkgd {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.75);
    }

    &__message {
        margin-bottom: 15px;
        text-align: center;
    }

    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        cursor: pointer;
        svg {
            width: 14px;
            height: 14px;
            margin-right: 5px;
        }
    }

    &__form {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        &--overlay {
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);
            max-width: 320px;
            padding: 40px 20px 20px;
            background-color: #fff;
            border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .form__row {
            margin-bottom: 15px;
        }

        .form__label {
            display: block;
        }

        .form__input {
            width: 100%;
            padding: 8px 10px;
            border: 1px solid #000;
        }

        button.form__button {
            display: inline-block;
            border: none;
            padding: 8px 20px;
            margin: 0;
            text-decoration: none;
            background: #000;
            border-radius: 20px;
            color: #ffffff;
            font-family: sans-serif;
            font-size: 16px;
            cursor: pointer;
            text-align: center;
            transition: background 250ms ease-in-out, transform 150ms ease;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:hover,
            &:focus {
                background: #339933;
            }

            &:focus {
                outline: 1px solid #fff;
                outline-offset: -4px;
            }

            &:active {
                transform: scale(0.99);
            }

            &:disabled,
            &[disabled] {
                background: #ccc;
            }
        }

        .btn__wrapper {
            position: relative;
        }

        .btn__validation {
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
        }

        .form__row--error {
            display: none !important;
        }

        &--error {
            .form__row--error {
                display: flex !important;
                color: #a00;
            }

            .form__select--newsletter label,
            .form__select--newsletter label::after {
                border-color: #a00 !important;
            }
        }

        @media screen and (min-width: $tablet) {
            .form__row {
                display: flex;
                justify-content: center;
            }
            .form__label {
                display: auto;
                width: 50px;
                padding-right: 15px;
                text-align: right;
            }

            .form__input {
                padding: 14px 22px;
            }

            .form__select--newsletter {
                width: 100%;
                background-color: #fff;
                display: block;
                position: relative;

                label {
                    padding: 10px 22px;
                    width: 100%;
                    display: block;
                    text-align: left;
                    color: #3c454c;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;
                    transition: color 200ms ease-in;
                    overflow: hidden;
                    border: 1px solid #000;

                    .boldcap {
                        font-weight: 700;
                    }

                    &:before {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        content: '';
                        background-color: #282;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%) scale3d(1, 1, 1);
                        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
                        opacity: 0;
                        z-index: -1;
                    }

                    &:after {
                        width: 28px;
                        height: 28px;
                        content: '';
                        border: 1px solid #999;
                        background-color: #fff;
                        background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
                        background-repeat: no-repeat;
                        background-position: 2px 3px;
                        border-radius: 50%;
                        z-index: 2;
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                        transition: all 200ms ease-in;
                    }
                }

                input:checked ~ label {
                    color: #fff;

                    &:before {
                        transform: translate(-50%, -50%) scale3d(56, 56, 1);
                        opacity: 1;
                    }

                    &:after {
                        background-color: #000;
                        border-color: #000;
                    }
                }

                input {
                    width: 32px;
                    height: 32px;
                    order: 1;
                    z-index: 2;
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    visibility: hidden;
                }
            }
        }
        @media screen and (min-width: $desktop) {
        }
    }
}
