.artist__entry {
    position: relative;
}
.artwork__image {
    display: block;
    position: relative;
    opacity: 1;
    transition: opacity 0.3s;
    &--init {
        opacity: 0;
    }
}

.artwork__item {
    margin-bottom: 30px;
    cursor: pointer;
    .artwork__info {
        height: 60px;
        padding-top: 5px;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }
    .artwork__title {
        display: inline-block;
        position: relative;
        font-size: 14px;
        font-style: italic;
        &.artwork__unavailable::after {
            content: "\2022";
            position: absolute;
            top: -5px;
            right: -8px;
            color: #900;
            font-size: 22px;
        }
    }
    @media screen and (min-width: $tablet) {
    }
}
.artist__works {
    display: none;
    .thumbnail__entry {
        margin-bottom: 30px;
        img {
            width: 100%;
        }
    }
    @media screen and (min-width: $tablet) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .thumbnail__entry {
            flex-basis: 50%;
            padding: 0 15px;
            margin-bottom: 30px;
        }
    }
    @media screen and (min-width: $desktop) {
        .thumbnail__entry {
            flex-basis: 25%;
        }
    }
}
