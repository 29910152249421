.news {
    .article {
        margin-bottom: 30px;
    }
    .article__image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .article__title {
        font-size: 22px;
        font-weight: 200;
        line-height: 1.25;
    }
    .article__info {
        margin-bottom: 15px;
    }
    .article__excerpt {
        img {
            width: 100%;
            height: auto;
        }
    }
    .icon--link {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        svg {
            width: 100%;
            height: 100%;
        }
    }
    .content__nav {
        text-align: center;
    }
    .nav__info {
    }
    .nav__arrow {
        display: inline-block;
        width: 24px;
        height: 24px;
        &--prev {
            transform: rotate(180deg);
            margin-right: 15px;
        }
        &--next {
            margin-left: 15px;
        }
        &--disabled {
            opacity: 0.25;
        }
    }
    @media screen and (min-width: $tablet) {
        .news__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 960px;
            margin: 0 auto;
            padding: 0 15px;
        }
        .article {
            width: 46%;
            margin-bottom: 45px;
            @include clearfix;
            &:nth-child(odd) {
                .article__image {
                    float: right;
                    margin-left: 20px;
                    margin-right: 0;
                }
            }
            &:nth-child(even) {
                .article__image {
                    float: left;
                    margin-left: 0;
                    margin-right: 20px;
                }
            }
        }
        .article__image {
            width: 140px;
            padding-top: 5px;
        }
        .article__content {
            //float: left;
            //width: 380px;
        }
        .article__title {
            margin: 0 0 5px 0;
        }
        .article__info {
            margin-bottom: 10px;
        }
        .content__nav {
            display: flex;
            justify-content: center;
            width: 280px;
            margin: 0 auto;
        }
    }
    @media screen and (min-width: $desktop) {
        .article__content {
            //width: 480px;
        }
    }
}
