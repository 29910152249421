.about {
    h2 {
        font-size: 24px;
    }
    .history__entry {
        margin-bottom: 20px;
    }
    .history__year {
        margin-bottom: 10px;
        padding-bottom: 3px;
        border-bottom: 1px solid #ccc;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        text-align: right;
    }
    .history__table {

    }
    .history__month {
        @include clearfix;
        margin-bottom: 10px;
    }
    .month__label {
        float: left;
        width: 50px;
        padding-right: 10px;
        opacity: 0.75;
        font-size: 13px;
        line-height: 18px;
        text-align: right;
    }
    .month__artists {
        float: left;
        width: 240px;
    }
    .artist__name {
        margin-bottom: 5px;
    }
    @media screen and (min-width: $tablet) {
        h1 {
            display: none;
        }
    }
    @media screen and (min-width: $desktop) {

    }
}
