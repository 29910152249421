.heed {
    &__main {
        padding: 0 15px;
        .main {
            &__image {
                width: 100%;
            }
            &__date {
                margin-bottom: 20px;
                text-align: right;
            }
            &__description {
                margin-bottom: 60px;
                color: #395a1a;
                font-size: 18px;
                font-weight: 300;
                line-height: 1.5;
                text-align: center;
            }
        }

        @media screen and (min-width: $desktop) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 240px 90px 0 0;

            .main {
                &__image {
                    width: 320px;
                }
                &__description {
                    width: 640px;
                    text-align: right;
                }
            }

            &--exhibition {
                align-items: center;
                padding: 0;

                .main {
                    &__image {
                        width: 180px;
                    }
                    &__date {
                        margin-bottom: 30px;
                    }
                    &__description {
                        display: none;
                    }
                }
            }
        }
    }

    &__artists {
        .artist {
            &__item {
                margin-bottom: 60px;
                text-align: center;
            }

            &__link {
                display: block;
            }

            &__image {
                width: 100%;
                max-width: 480px;
                margin-bottom: 5px;
            }

            &__name {
                font-size: 16px;
            }
        }
        @media screen and (min-width: $desktop) {
            display: flex;
            flex-wrap: wrap;
            .artist {
                &__item {
                    flex-basis: 50%;
                    align-self: center;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 60px;
                    text-align: center;
    
                    &--1 {
                        order: 1;
                    }
                    &--2 {
                        order: 2;
                    }
                    &--3 {
                        order: 3;
                    }
                    &--4 {
                        order: 4;
                    }
                }
            }

            &--exhibition {
                .artist {
                    &__item {
                        flex-basis: 25%;
                        padding: 0 15px;
                    }
                }
            }
        }
    }

    &__artist {
        .artist {
            &__intro {
                padding: 0 5px 30px;
            }
            &__name {
                margin: 0 0 10px 0;
                font-size: 28px;
                font-weight: 200;
                line-height: 28px;
                text-align: center;
            }
            &__statement {
                margin-bottom: 15px;
                color: #395a1a;
                text-align: left;
            }
            &__donation {
                font-size: 12px;
                line-height: 1.25;
            }
            &__charity {
                a {
                    color: #395a1a;
                    font-size: 18px;
                    font-style: italic;
                }
            }
            &__works {
                display: none;
            }
        }

        @media screen and (min-width: $desktop) {
            .artist {
                &__intro {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-bottom: 30px;
                    padding: 0 90px 30px 0;
                }
                &__statement {
                    padding-left: 20%;
                    text-align: right;
                }
                &__donation {
                    text-align: center;
                }
                &__works {
                    display: flex;
                    margin-bottom: 60px;
                }
                &__video {
                    display: flex;

                    .video {
                        &__item {
                            flex-basis: 25%;
                            position: relative;
                            margin: 0 15px;
    
                            &--blank {
                                border-top: 1px solid #395a1a;
                            }
                            &--poster {
                                cursor: pointer;
                            }
                        }

                        &__image {
                            border: 1px solid #395a1a;
                        }

                        &__play {
                            position: absolute;
                            bottom: 20px;
                            left: 20px;
                            width: 24px;
                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}