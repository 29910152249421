.exhibition__group--dailyart {
  .dailyart__list {
    max-width: 640px;
    margin: 0 auto;
  }
  .dailyart__item {
    margin-bottom: 30px;
  }
  .artwork__image {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: $tablet) {
    margin-bottom: 90px;
    .dailyart__item {
      margin: 0;
      padding: 0;
    }
  }
  @media screen and (min-width: $desktop) {
  }
}
