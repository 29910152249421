footer {
  max-width: 1280px;
  margin: 0 auto;
  padding: 90px 0 45px 0;
  text-align: center;

  @media screen and (min-width: $tablet) {
  }
  @media screen and (min-width: $desktop) {
  }
}

.footer__social {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .icon {
    display: block;
    margin: 0 5px;
    width: 20px;
    height: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
    &--facebook {
      position: relative;
      margin-left: 20px;
      &::before {
        content: "M";
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
      }
      &.memphis::before {
        content: "M";
      }
      &.nashville::before {
        content: "N";
      }
    }
  }
  @media screen and (min-width: $tablet) {
  }
  @media screen and (min-width: $desktop) {
  }
}

.footer__newsletter {
  margin-top: 15px;
  font-size: 16px;
  @media screen and (min-width: $tablet) {
  }
  @media screen and (min-width: $desktop) {
  }
}
body.newsletter .footer__newsletter {
  display: none;
}

.footer__copyright {
  margin-top: 30px;
}
