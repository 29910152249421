.exhibitions_entry {
  .page__title--group {
    font-style: italic;
  }
}
.exhibition__entry {
  .exhibition {
    &__card {
      margin-bottom: 30px;
      padding: 0 15px;
      text-align: center;
    }
    &__title {
      font-size: 22px;
      font-style: italic;
      font-weight: 200;
      line-height: 22px;
      &--group {
        font-style: normal;
      }
    }

    &__info {
      display: flex;
      margin-top: 30px;
      margin-bottom: 60px;
      text-align: left;
    }

    &__feature {
      width: 40%;
      padding-top: 5px;

      .artwork__title {
        font-style: italic;
      }

        .artwork__image {
            width: 100%;
            height: auto;
        }

      &.js-overlay {
        cursor: pointer;
      }
    }

    &__statement {
      width: 60%;
      padding: 0 30px;
      p {
        margin: 0 0 1em;
      }
    }

    &__catalog {
      display: inline-block;
      position: relative;
      margin: 14px 20px 0 0;
      padding: 8px 0 0 32px;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 28px;
        height: 28px;
        background-image: url("../svg/icon-pdf.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__events {
      padding-top: 10px;
    }

    &__video {
      display: none;
      margin: 0 auto;
      max-width: 960px;

      &--active {
        display: block;
      }
    }

    &__works {
      display: none;
      &--inactive {
        display: none;
      }
    }

    &__trigger {
      margin-bottom: 15px;
      text-align: center;
      .trigger {
        display: inline-block;
        cursor: pointer;
        &--active {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .exhibition {
      &__works {
        display: flex;
        &--inactive {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: $desktop) {
  }
}
