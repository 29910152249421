.works__overlay {
    .overlay__bkgd,
    .overlay__control,
    .overlay__close {
        display: none;
    }
    .artwork__image {
        width: 100%;
        height: auto;
    }
    @media screen and (min-width: $tablet) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &--active {
            display: block;
        }
        .overlay__bkgd {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }
        .overlay__control {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            .control__item {
                position: absolute;
                top: 0;
                width: 45%;
                height: 100%;
                cursor: pointer;
                &--prev {
                    left: 0;
                }
                &--next {
                    right: 0;
                }
            }
            .item__label {
                position: absolute;
                top: 50%;
                width: 24px;
                height: 24px;
                transform: translateY(-50%);
                &--prev {
                    left: 50px;
                    transform: rotate(180deg);
                }
                &--next {
                    right: 50px;
                }
            }
        }
        .overlay__close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            .close__icon {
                position: absolute;
                width: 24px;
                height: 24px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .overlay__video {
            max-width: 1280px;
            margin: 0 auto;
        }
        .overlay__items {
            // position: relative;
            // width: auto;
            // height: 100%;
        }
        .flex__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
        .artwork__item {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 100%;
            margin: 0 auto;
            opacity: 0;
            transition: opacity 0.5s ease;
            &--active {
                opacity: 1;
            }
            &--sq {
                .artwork__image {
                    max-width: 85vh;
                    max-height: 85vh;
                }
            }
            &--h {
                .artwork__image {
                    max-width: 85vh;
                }
            }
            &--v {
                .artwork__image {
                    max-height: 85vh;
                }
            }
        }
        .artwork__image {
            width: auto;
        }
        .artwork__info {
            padding: 5px 5px;
        }
    }
}

.video__overlay {
    .overlay__bkgd,
    .overlay__close {
        display: none;
    }
    .artwork__image {
        width: 100%;
        height: auto;
    }
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        
        &--active {
            display: block;
        }
        .overlay__bkgd {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #fff;
        }
        .overlay__control {
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            .control__item {
                position: absolute;
                top: 0;
                width: 45%;
                height: 100%;
                cursor: pointer;
                &--prev {
                    left: 0;
                }
                &--next {
                    right: 0;
                }
            }
            .item__label {
                position: absolute;
                top: 50%;
                width: 24px;
                height: 24px;
                transform: translateY(-50%);
                &--prev {
                    left: 50px;
                    transform: rotate(180deg);
                }
                &--next {
                    right: 50px;
                }
            }
        }
        .overlay__close {
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            .close__icon {
                position: absolute;
                width: 24px;
                height: 24px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .overlay__video {
            max-width: 1280px;
            margin: 0 auto;
        }
        .overlay__items {
            // position: relative;
            // width: auto;
            // height: 100%;
            max-width: 1280px;
            margin: 0 auto;
            padding-top: 60px;
        }
        .flex__wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
        .artwork__image {
            width: auto;
        }
}