.history {
    .history__nav {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .nav__year {
            display: none;
            margin: 15px;
            &--current {
                display: block;
                font-size: 18px;
                font-weight: 600;
            }
            &--previous,
            &--next {
                display: block;
            }
        }
        &--desktop {
            display: none;
        }
    }
    .history__list {
        margin-bottom: 60px;
    }

    .list__item {
        margin-bottom: 30px;
        .flex__container {
            display: flex;
        }
    }
    .list__image {
        flex-basis: 50%;
        &--empty {
            border: 1px solid #999;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .list__text {
        flex-basis: 50%;
        padding-left: 15px;
    }
    .entry__title {
        margin-bottom: 3px;
        font-size: 18px;
        line-height: 1.25;
        &--group {
            font-style: italic;
        }
    }
    .entry__exhibition {
        font-style: italic;
        line-height: 1.35;
        &--group {
            font-style: normal;
        }
    }
    .entry__date {
    }

    .entry__location {
        margin-top: 5px;
    }

    .gallery__location {
        margin: 10px 0 15px;
        .icon__container {
            height: 24px;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        &--memphis {
            .icon__container {
                width: 20px;
            }
        }
        &--nashville {
            .icon__container {
                width: 14px;
            }
        }
    }

    @media screen and (min-width: $tablet) {
        .history__content {
            display: flex;
            justify-content: center;
        }

        .history__nav {
            width: 130px;
            margin-top: 50px;
            padding: 0 40px;
            text-align: center;
            .nav__year {
                display: block;
                margin: 0;
            }
            &--mobile {
                display: none;
            }
            &--desktop {
                display: block;
            }
        }
        .gallery__location {
            &--memphis {
                padding-right: 50px;
                text-align: right;
                .icon__container {
                    display: inline-block;
                }
            }
            &--nashville {
                padding-left: 50px;
            }
        }

        .history__list {
            width: 320px;
            &--memphis {
                .flex__container {
                    justify-content: flex-end;
                }
                .list__image {
                    order: 2;
                }
                .list__text {
                    order: 1;
                    padding-left: 0;
                    padding-right: 15px;
                    text-align: right;
                }
            }
        }
        .list__item {
            width: 100%;
            margin-bottom: 20px;
        }
        .list__image {
            flex-basis: auto;
            width: 145px;
            height: 145px;
        }
        .list__content {
            flex-basis: auto;
        }
    }
}
