$impact: Impact, $font-default;

.home__dailyart {
  margin-bottom: 90px;

  .artwork__image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .text {
    font-family: $impact;
    font-weight: 700;
    text-transform: uppercase;
    &__title {
      margin: 0;
      color: #346f55;
      font-size: 60px;
      line-height: 60px;
    }
    &__artist {
      margin-bottom: 10px;
      color: #346f55;
      font-size: 36px;
      line-height: 36px;
    }
    &__artwork {
      color: #666;
      font-family: $font-default;
      font-size: 24px;
      font-style: italic;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: none;
    }
    &__info {
      color: #666;
      font-family: $font-default;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-transform: none;
    }
    &__purchase {
      margin-top: 45px;
      margin-bottom: 5px;
      color: #666;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .title__image {
    max-width: 680px;
    margin: 0 auto 30px auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  .dailyart__description {
    margin: 30px 0;

    a {
      color: #346f55;
    }
  }

  .dailyart__purchase {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .purchase {
    &__icon {
      padding: 7px 18px 0 0;
    }

    &__price {
      padding: 8px 20px 8px 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
    }

    &__button {
      display: inline-block;
      border: none;
      padding: 0px 20px;
      margin: 0;
      text-decoration: none;
      background: #000;
      border-radius: 20px;
      color: #ffffff;
      font-family: sans-serif;
      font-size: 16px;
      line-height: 1.75;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
      &:hover,
      &:focus {
        background: #339933;
      }

      &:disabled {
        background: #888;
        cursor: default;
      }

      &:focus {
        outline: 1px solid #339933;
        outline-offset: -5px;
      }

      &:active {
        transform: scale(0.99);
      }

      &--hide {
        display: none;
      }
    }

    &__form {
      display: none;
      &--visible {
        display: block;
      }

      .field-container input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      .field-container {
        position: relative;
        margin-bottom: 10px;
      }

      .container-title {
        margin: 30px 0 10px;
      }

      .contact-container {
        display: block;
        max-width: 400px;
        margin-bottom: 30px;
        padding: 0;
        color: #707070;
      }

      label {
        margin-bottom: 3px;
        padding-bottom: 5px;
        font-size: 13px;
        span {
          color: #900;
        }
      }

      input {
        padding: 10px 15px;
        font-size: 16px;
        width: 100%;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
      }

      .input__wrapper {
        position: relative;
      }
    }
  }

  .carousel-item {
    display: none;
  }
  .carousel-item-active {
    display: block;
  }
  .carousel-image {
    position: relative;
  }
  .carousel-control {
    display: none;
  }

  .upcoming__exhibition {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    transform: translate(25%, 25%);
    img {
      position: absolute;
      width: 100%;
      height: auto;
    }
    .badge-animation {
      animation: spin 20s linear infinite;
    }
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .upcoming__exhibition {
      width: 125px;
      height: 125px;
    }
  }

  @media screen and (min-width: $desktop) {
    .flex__row {
      display: flex;
      max-width: 1080px;
      margin: 0 auto;
    }
    .flex__col {
      flex-basis: 50%;
      padding: 0 15px;
      &--artwork {
        display: flex;
        justify-content: flex-end;
      }
    }

    .flex__content {
      position: relative;
      max-height: 480px;
    }
    .artwork__image {
      margin-top: 3px;
      margin-bottom: 0;
    }
    .dailyart__description {
      padding-right: 26%;
    }
    .dailyart__purchase {
      padding-right: 26%;
    }

    .carousel-mobile {
      display: none;
    }

    .carousel {
      position: relative;
      width: 100%;
    }
    .carousel-item {
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      right: -50px;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
    }
    .carousel-item-active {
      opacity: 1;
      right: 0;
    }
    .carousel-item-exit {
      right: 50px;
    }
    .carousel-image {
      position: relative;
    }
    .carousel-image-title {
        margin-top: 10px;
        font-size: 14px;
        font-style: italic;
        line-height: 16px;
        text-align: right;
    }
    .carousel-image-description {
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
        text-align: right;
    }
    .carousel-control {
      display: block;
      position: absolute;
      right: 0;
      width: 100%;
      transition: top 0.4s ease-in-out;
      cursor: pointer;
    }
    .carousel-indicators {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-top: 8px;
        padding-right: 25px;
    }
    .carousel-indicator-item {
        width: 6px;
        height: 6px;
        margin: 0 3px;
        background-color: #333;
        opacity: .25;
        border-radius: 50%;
    }
    .carousel-indicator-feature {
        width: 9px;
        height: 9px;
    }
    .carousel-indicator-active {
        background-color: #346f55;
        opacity: 1;
    }
    .carousel-arrow {
        position: absolute;
        top: 0;
        right: 0;
    }
    .upcoming__exhibition {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 150px;
      height: 150px;
      transform: translate(-25%, 25%);
    }
  }
}
