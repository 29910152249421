.exhibition__group {
  margin-bottom: 60px;
}

.exhibition__item {
  margin-bottom: 30px;
}

.exhibition__image {
  .artwork__image {
    width: 100%;
    height: auto;
  }
}

.exhibition__item--history {
  .exhibition__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 290px;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  .artwork__image--spacer {
    display: none;
  }
  .icon {
    width: 96px;
    height: 96px;
  }
}

.image__text {
  display: block;
  &--gallery {
    font-size: 45px;
    line-height: 45px;
    font-weight: 200;
  }
  &--history {
    font-size: 32px;
    line-height: 32px;
    font-weight: 200;
  }
}
.exhibition__artist {
  margin-top: 10px;
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 18px;
  &--group {
    font-style: italic;
  }
}
.exhibition__title {
  margin-bottom: 8px;
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  &--group {
    font-style: normal;
  }
}
.exhibition__list--upcoming,
.exhibition__list--previous {
  .exhibition__image {
    &--empty {
      display: none;
    }
  }
}
.exhibition__location {
  display: inline-block;
  margin-right: 10px;
  font-weight: 700;
}
@media screen and (min-width: $tablet) {
  .exhibition__item {
    text-align: center;
  }
  .exhibition__artist {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 28px;
    line-height: 28px;
  }
  .exhibition__title {
    font-size: 18px;
    line-height: 24px;
  }
  .exhibition__list--current {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .exhibition__item {
      flex-basis: 25%;
      padding: 0 15px;
      .exhibition__image {
        .artwork__image {
          width: 290px;
          height: 290px;
        }
      }
      &--2,
      &--3 {
        flex-basis: 50%;
        .exhibition__image {
          display: flex;
          justify-content: space-between;
        }
      }
      &--3 {
        .exhibition__image {
          .artwork__image {
            width: 190px;
          }
        }
      }
    }

    .exhibition__artist {
      font-size: 21px;
      line-height: 21px;
    }

    // &.list__length--2 {
    //   .exhibition__item {
    //     flex-basis: 50%;
    //     padding: 0;
    //   }
    //   .item__content {
    //     display: flex;
    //   }
    //   .exhibition__image {
    //     flex-basis: 50%;
    //   }
    //   .exhibition__info {
    //     flex-basis: 50%;
    //     padding: 0 15px;
    //   }
    // }
  }

  .list__length--1 {
    .exhibition__item {
      flex-basis: auto;
      max-width: 640px;
      .exhibition__image {
        .artwork__image {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .list__length--2 {
    .exhibition__item {
      flex-basis: 35%;
      .exhibition__image {
        .artwork__image {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .exhibition__list--upcoming,
  .exhibition__list--previous {
    display: flex;
    flex-wrap: wrap;
    .exhibition__item {
      flex-basis: 33.333%;
      padding: 0 15px;
      margin-bottom: 30px;
    }
    .exhibition__image {
      &--empty {
        display: block;
        border: 1px solid #333;
        opacity: 0.25;
      }

      &--history {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        width: auto;
        height: auto;
      }
    }
    .exhibition__artist {
      margin-top: 10px;
      font-size: 21px;
      line-height: 24px;
    }
    .exhibition__title {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .exhibition__item--history {
    .exhibition__image {
      display: block;
      position: relative;
      width: auto;
      height: auto;
    }
    .artwork__image--spacer {
      display: block;
      width: 100%;
      height: 100%;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media screen and (min-width: $desktop) {
  .exhibition__list--upcoming,
  .exhibition__list--previous {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .exhibition__item {
      flex-basis: 20%;
      padding: 0 15px;
      margin-bottom: 30px;
    }
  }
}
