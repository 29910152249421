.home__features {
    margin-bottom: 30px;
}
.feature__item {
    max-width: 768px;
    margin: 0 auto 15px auto;
    &--text {
        text-align: center;
    }
    .feature__image {
        img {
            width: 100%;
            height: auto;
        }
    }
    .feature__text {
        display: inline-block;
        position: relative;
        margin: 15px 0 30px;
        color: #231900;
        font-size: 28px;
        font-weight: 300;
        letter-spacing: -1px;
        line-height: 1.2;

        text-align: center;
        p {
            margin: 0;
        }
        strong {
            //background-color: var(--highlight);
            //border-bottom: 3px solid var(--highlight);
            //color: var(--highlight);
            //color: #fff;
            font-weight: 700;
        }
    }
    .feature__label {
        display: inline-block;
        padding: 3px 15px 2px 15px;
        background-color: var(--highlight);
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0;
        text-transform: uppercase;
    }
    @media screen and (min-width: $tablet) {
        .label__wrapper {
            position: absolute;
            top: -30px;
            left: 0px;
            z-index: -1;
        }
    }
    @media screen and (min-width: $desktop) {
    }
}
