.home {
  &.content {
    position: relative;
  }
  .specials__badge {
    position: absolute;
    top: -20px;
    right: 10px;
    z-index: 9;
    width: 100px;
    height: 100px;

    .badge__link {
      display: block;
    }

    .badge__icon {
      transform: rotate(10deg);
    }

    .badge__text {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(10deg);
      opacity: 0.8;
      color: #fff;
      font-family: "Dancing Script";
      font-size: 32px;
      line-height: 24px;
      text-align: center;
      text-shadow: 1px 1px 1px #000;
    }
    @media screen and (min-width: $tablet) {
      top: -65px;
      right: 30px;
    }
    @media screen and (min-width: $desktop) {
      top: -40px;
      right: -40px;
    }
  }
}

.home__feature {
  margin: 0 auto;
  @media screen and (min-width: $tablet) {
    max-width: 960px;
    margin: 0 auto 30px auto;
    &--top {
      width: 640px;
    }
  }
  @media screen and (min-width: $desktop) {
    width: 960px;
  }
}

.home__content {
  @media screen and (min-width: $tablet) {
    display: flex;
    max-width: 960px;
    margin: 0 auto;
    .flex__column {
      flex-basis: 50%;
    }
  }
  @media screen and (min-width: $desktop) {
  }
}

.home__exhibitions {
  .exhibition {
    &__item {
      text-align: center;

      &--special {
        position: relative;

        .exhibition__info {
          padding: 5px 25px 0;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    &__image {
      &--special {
        display: block;
        border: 3px dashed #dcdcdc;
      }
    }
  }


  @media screen and (min-width: $tablet) {
    display: flex;
    justify-content: space-around;
    max-width: 1080px;
    margin: 0 auto;
    .exhibition__list {
        flex-basis: 50%;
    }
    .exhibition__item {
        //flex-basis: 50%;
        margin: 0 15px;
    }
    // .exhibition__item {
    //   flex-basis: 25%;
    //   padding: 0 15px;
    //   .exhibition__image {
    //     .artwork__image {
    //       width: 290px;
    //       height: 290px;
    //     }
    //   }
    //   &--2,
    //   &--3 {
    //     .exhibition__image {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       .artwork__image {
    //         margin-bottom: 15px;
    //       }
    //     }
    //   }
    //   &--3 {
    //     .exhibition__image {
    //       .artwork__image {
    //         width: 190px;
    //         margin-bottom: 15px;
    //       }
    //     }
    //   }
    // }
  }
  @media screen and (min-width: $desktop) {
  }
}

.exhibition__events {
  @media screen and (min-width: $tablet) {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    .event__item {
      margin-bottom: 15px;
      padding: 0 15px;
    }
    .event__type {
      font-weight: 700;
      line-height: 14px;
    }
    .event__description {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      p {
        margin: 0 0 10px;
      }
    }
  }
  @media screen and (min-width: $desktop) {
  }
}
