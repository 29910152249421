.contact__content {
    .content--desktop {
        display: none;
    }
    .contact__subhead {
        margin: 0;
        text-align: center;
        font-size: 18px;
        font-weight: 300;
    }
    .gallery__entry {
        margin-bottom: 30px;
    }
    .gallery__map {
        width: 100%;
        height: auto;
    }
    .gallery__location {
        height: 24px;
        margin: 10px 0 5px;
        svg {
            width: 100%;
            height: 100%;
        }
        &--memphis {
            width: 20px;
        }
        &--nashville {
            width: 14px;
        }
    }
    .gallery__hours {
        margin-bottom: 10px;
    }
    .gallery__address {
        margin: 15px 0;
    }
    .gallery__phone {
        margin-bottom: 30px;
    }
    .gallery__about {
        max-width: 640px;
        margin: 30px auto;
    }
    @media screen and (min-width: $tablet) {
        .content {
            &--mobile {
                display: none;
            }
            &--desktop {
                display: block;
            }
        }
        .contact__subhead {
            font-size: 24px;
        }
        .gallery__locations {
            display: flex;
        }
        .gallery__entry {
            flex-basis: 50%;
            padding: 0 15px;
            &--memphis {
                text-align: right;
            }
        }
        .gallery__location {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .gallery__hours {
            text-align: center;
        }
        .gallery__global {
            text-align: center;
        }
        .gallery__phone {
            margin-bottom: 0;
        }
        .gallery__owner {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }
    @media screen and (min-width: $desktop) {
        .gallery__locations {
            justify-content: space-evenly;
        }
        .gallery__entry {
            flex-basis: 25%;
        }
    }
}
