header {
  padding-top: 0px;
  border-top: 2px solid var(--highlight);
  .header__logo {
    width: 250px;
    height: 45px;
    margin: 0 auto;
  }
  .header__locations {
    font-size: 18px;
    text-align: center;
    .pipe {
      margin: 0 4px;
      color: #ccc;
    }
  }
  nav {
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
  }
  .nav__item {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    padding-top: 2px;
    color: #000;
    font-size: 14px;
    text-decoration: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      border-top: 2px solid var(--highlight);
    }
    &:hover,
    &--active {
      &::before {
        transform: scaleX(1);
      }
    }
  }
  @media screen and (min-width: $tablet) {
    .nav__item {
      margin: 0 20px;
    }
  }
  @media screen and (min-width: $desktop) {
  }
}
