.artist__entry {
    .artist__title {
        margin-bottom: 60px;
        @include clearfix;
    }

    .artist__name {
        margin-bottom: 30px;
        text-align: center;
        h1 {
            margin: 0 0 15px 0;
            font-size: 40px;
            line-height: 40px;
        }
        .media__title {
            margin-left: 20px;
            font-style: italic;
            font-weight: 200;
        }
    }

    .artist__feature {
        .artwork__image {
            width: 100%;
            height: auto;
        }
    }

    .artist__quote {
        margin: 15px 0 30px 0;
        padding: 0 30px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        color: #000;
        font-size: 24px;
        font-style: italic;
        font-weight: 300;
        text-align: center;
        .quote__wrapper {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: -5px;
                left: -40px;
                width: 32px;
                height: 32px;
                background-image: url("../svg/icon-quote.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }

    .artist__bio {
        p {
            margin: 0 0 1em 0;
        }
    }

    .split__link {
        &--inactive {
            display: none;
        }
    }

    .text__split {
        display: none;
        &--active {
            display: block;
        }
    }

    .bio__link {
        display: inline-block;
        position: relative;
        margin: 14px 20px 0 0;
        padding: 8px 0 0 32px;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &--pdf::before {
            width: 28px;
            height: 28px;
            background-image: url("../svg/icon-pdf.svg");
        }
        &--book::before {
            top: 1px;
            width: 15px;
            height: 16px;
            background-image: url("../svg/icon-book.svg");
        }
        &--video::before {
            top: 1px;
            width: 28px;
            height: 28px;
            background-image: url("../svg/icon-video.svg");
        }
        &--artwork::before {
            top: 1px;
            width: 14px;
            height: 14px;
            background-image: url("../svg/icon-artwork.svg");
        }
    }

    @media screen and (min-width: $tablet) {
        .artist__title {
            padding: 0 15px;
        }
        .artist__feature {
            float: left;
            width: 40%;
            padding-top: 5px;
            .artwork__image {
                width: 100%;
                height: auto;
            }
        }

        .artist__quote {
            max-width: 50%;
            margin: 20px auto 30px auto;
        }
        .artist__bio {
            float: left;
            width: 60%;
            padding: 0 30px;
        }
    }
    @media screen and (min-width: $desktop) {
    }
}
