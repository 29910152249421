//
// base styles
html {
    box-sizing: border-box;
}
*,
*::after,
*::before {
    box-sizing: inherit;
}
body {
    background-color: #fff;
    font-family: $font-default;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.75;
}

html.noscroll,
html.noscroll body {
    overflow: hidden;
}

img {
    display: block;
}

a {
    color: #000;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

strong {
    font-weight: 700;
}

h1.page__title {
    margin: 0 0 30px 0;
    font-size: 28px;
    font-weight: 200;
    line-height: 28px;
    text-align: center;
    &--exhibitions_entry {
        margin: 0 0 10px 0;
    }
    @media screen and (min-width: $tablet) {
        font-size: 32px;
        font-weight: 200;
        line-height: 32px;
        &--artists_entry {
            margin: 0 0 5px 0;
            font-size: 38px;
            line-height: 38px;
        }
        &--exhibitions_entry {
            margin: 0 0 10px 0;
            font-size: 38px;
            line-height: 38px;
        }
    }
}

.content {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 45px 15px 0;
    @media screen and (min-width: $tablet) {
    }
    @media screen and (min-width: $desktop) {
        padding: 45px 0 0;
    }
}

.section__title {
    margin: 0 0 20px 0;
    font-size: 24px;
    font-weight: 200;
    line-height: 24px;
    text-align: center;
}

.content__404 {
    width: 480px;
    margin: 0 auto;
    text-align: center;
    img {
        width: 100%;
        height: auto;
    }
}
