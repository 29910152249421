.artists {
    .artist__list {
        text-align: center;
        .list__item {
            margin-bottom: 15px;
        }

        &[data-filter=represented] {
            .list__item[data-type=available] {
                display: none;
                pointer-events: none;
                cursor: default;
            }
        }

        &[data-filter=available] {
            .list__item[data-type=represented] {
                display: none;
                pointer-events: none;
                cursor: default;
            }
        }
    }

    .artist__filter {
        margin-bottom: 30px;
        text-align: center;

        .filter {
            &__item {
                display: inline-block;
                position: relative;
                margin: 0 5px;

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transform: scaleX(0);
                    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
                    border-bottom: 1px solid #000;
                    pointer-events: none;                  
                }

                &--active {
                    font-weight: 600;
                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    @media screen and (min-width: $tablet) {
        .artist__list {
            display: flex;
            justify-content: space-around;
            .list__column {
                flex-basis: 33.333%;
            }
            .list__item {
                transition: opacity 200ms ease;
            }
        }

    }
    @media screen and (min-width: $desktop) {
        .artist__filter {
            .filter {
                &__item {
                    &[data-filter=represented] { 
                        left: 40%;
                    }
    
                    &[data-filter=available] {
                        left: 60%;
                    }
                }
            }
        }
    }
}
