.news_entry {
    .article__content {
        a {
            font-weight: 700;
            text-decoration: underline;
            &:hover {
                color: $violet;
                text-decoration: none;
            }
        }
    }
    .article__info {
        text-align: center;
    }
    .article__intro {
        font-size: 18px;
    }
    .content__text {
        text-align: left;
    }
    .content__image {
        max-width: 768px;
        margin: 0 auto 30px auto;
        &--sq,
        &--h {
            .img {
                width: 100%;
                height: auto;
            }
        }
        &--v {
            .img {
                width: auto;
                height: 640px;
                margin: 0 auto;
            }
        }
    }
    .image__caption {
        padding-top: 5px;
        font-size: 12px;
        font-style: italic;
        text-align: center;
    }
    .image__strip {
        .img__container {
            margin-bottom: 10px;
            img {
                width: 100%;
                height: auto;
            }
        }
        @media screen and (min-width: $tablet) {
            display: flex;
            .img__container {
                margin-left: 5px;
                margin-bottom: 0;
                &:first-child {
                    margin-left: 0;
                }
                &--3,
                &--4 {
                    margin-left: 5px;
                }
            }
        }
    }
    .content__quote {
        margin: 30px 0;
        padding: 0 5px 0 40px;
        color: #000;
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
        text-align: left;
        .quote__wrapper {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: -5px;
                left: -40px;
                width: 32px;
                height: 32px;
                background-image: url('../svg/icon-quote.svg');
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .quote__author {
            margin-top: 15px;
            font-style: normal;
            font-weight: 600;
        }
        @media screen and (min-width: $tablet) {
            padding: 30px 60px;
            font-size: 24px;
            .quote__author {
                margin-top: 10px;
                font-size: 18px;
            }
        }
    }
    .content__video {
        position: relative;
        margin-bottom: 30px;
        padding: 56.25% 0 0 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (min-width: $tablet) {
        .article__content {
            max-width: 768px;
            margin: 0 auto;
            padding: 0 15px;
        }
        .content__text {
            text-align: left;
        }
    }
    @media screen and (min-width: $desktop) {
    }
}
