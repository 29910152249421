.calendar {
    &__section {
        padding-bottom: 30px;

        h4 {
            text-align: center;
        }
    }
    &__item {
        margin-bottom: 30px;
        text-align: center;
    }

    &__date {
        margin-bottom: 5px;
        &--event {
            div {
                display: inline-block;
            }
        }
    }

    .event {
        &__month {
            margin-right: 5px;
        }
    }

    &__location {
        margin-left: 5px;
        font-weight: 700;
    }

    &__artist {
        margin-bottom: 5px;
        font-size: 21px;
        font-weight: 300;
        line-height: 1.1;
    }

    &__title {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.1;
    }

    &__description {
        padding: 0 10%;
        p {
            margin: 0;
        }
    }

    @media screen and (min-width: $tablet) {
        &__content {
            display: flex;
            justify-content: space-around;
            max-width: 960px;
            margin: 0 auto;
        }

        &__column {
            flex-basis: 30%;
            margin: 0 15px;
        }

        &__section {
            h4 {
                padding-bottom: 5px;
                border-bottom: 1px solid #ddd;
                text-align: center;
                &.section--events {
                    text-align: center;
                }
            }
        }

        &__item {
            text-align: left;

            &--event {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

        &__date {
            &--event {
                min-width: 100px;
                margin-right: 30px;
                div {
                    display: block;
                    text-align: right;
                }

                .calendar__location {

                }
            }
        }

        .event {
            &__day {
                font-size: 32px;
                font-weight: 700;
                line-height: 1.15;
                .divider {
                    margin: 0 3px;
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 1;
                }
            }
            &__month {
                margin-right: 0;
                font-size: 15px;
                font-weight: 700;
                line-height: 1;
            }

            &__info {
                margin-top: 7px;
            }
        }

        &__description {
            margin: 10px 0 0 2px;
            padding: 0 0 0 10px;
            border-left: 1px solid var(--highlight);
        }
    }
}
.--calendar {
    &__content {
        display: flex;
        justify-content: space-around;
        max-width: 960px;
        margin: 0 auto;
    }

    &__column {
        flex-basis: 33.333%;
    }

    &__item {
        margin-bottom: 30px;

        .exhibition__location {
            margin: 0 0 0 10px;
        }
    }

    &__date {
        margin-bottom: 5px;
    }

    &__artist {
        margin-bottom: 5px;
        font-size: 21px;
        font-weight: 300;
        line-height: 1.1;
    }

    &__title {
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.1;
    }

    &__location {

    }

    &__description {
        margin: 10px 0 0 10px;
        padding-left: 10px;
        border-left: 1px solid #999;
        p {
            margin: 0;
        }
    }

    .event {
        &__item {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 45px;
            padding: 0 10px;
        }
        &__day {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.15;
            text-align: center;
            .divider {
                margin: 0 3px;
                font-size: 24px;
                font-weight: 300;
                line-height: 1;
            }
        }
        &__month {
            font-size: 15px;
            font-weight: 700;
            line-height: 1;
            text-align: center;
        }
        &__info {
        }
        &__artist {
            margin-top: 15px;
            font-size: 21px;
            font-weight: 300;
            line-height: 1.1;
            text-align: center;
        }
        &__title {
            font-size: 15px;
            font-weight: 600;
            line-height: 1.1;
            text-align: center;
        }
        &__description {
            margin-top: 5px;
            font-size: 14px;
            line-height: 1.4;
            text-align: center;
            p {
                margin: 0;
            }
        }
        &__location {
            font-weight: 700;
        }
        &__exhibition {
            font-size: 14px;
        }
        @media screen and (min-width: $tablet) {
            &__list {
                display: flex;
                flex-direction: column;

            }
            &__item {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
            }
            &__date {
                width: 100px;
                margin-right: 30px;
            }

            &__info {
                width: 226px;
            }

            &__location {
                text-align: right;
            }

            &__day,
            &__month,
            &__time {
                text-align: right;
            }
            &__type {
                margin-top: 8px;
                font-size: 12px;
            }
            &__artist {
                margin-top: 5px;
                margin-bottom: 5px;
                text-align: left;
            }
            &__title {
                margin-top: 0px;
                text-align: left;
            }
            &__description {
                text-align: left;
            }
        }
        @media screen and (min-width: $desktop) {
        }
    }
}
