.specials_entry {
    margin-bottom: 90px;
    .page__title {
        color: var(--highlight);
        font-family: 'Dancing Script';
        font-size: 48px;
        font-weight: 400;
        line-height: 48px;
    }
    .artwork__image {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    .artist__name {
        margin: 0 0 10px 0;
        font-size: 28px;
        font-weight: 200;
        line-height: 28px;
    }
    @media screen and (min-width: $desktop) {
        .flex__row {
            display: flex;
        }
        .flex__col {
            flex-basis: 50%;
            padding: 0 15px;
            &--artwork {
                display: flex;
                justify-content: flex-end;
            }
        }
        .artwork__image {
            margin-top: 3px;
            margin-bottom: 0;
        }

        .artwork__title {
            color: #666;
            font-family: $font-default;
            font-size: 24px;
            font-style: italic;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: 1px;
            text-transform: none;
        }
        .artwork__description {
            color: #666;
            font-family: $font-default;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            text-transform: none;
        }
        .specials__description {
            padding-right: 33%;
        }
    }
}
