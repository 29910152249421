.summer-camp {
    .page__title--image {
        max-width: 640px;
        margin: 0 auto;
        img {
            width: 100%;
            height: auto;
        }
    }
  .page__body {
    margin: 0 auto;
    max-width: 960px;
    text-align: center;
  }
  .page__content {
    .entry {
      margin: 15px;
      flex-basis: 33%;
      cursor: pointer;
    }

    .image {
      width: 100%;
      height: auto;
    }
  }


  .iframe__container {
    position: relative;
    padding: 300px 0;
    @media screen and (min-width: $tablet) {
        padding: 56.25% 0 0 0;
    }
  }

  #vimeo iframe,
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  @media screen and (min-width: $tablet) {
    .page__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media screen and (min-width: $desktop) {
    .page__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
